export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    $(function(){ 
      /* All hotels booking widget **********************/
      let headerbookingurlAll = 'https://bookings.hopsoftware.com//propertygroup/Check-Inn-Hotels';
      const formElemAll = document.querySelector('#booking-widget-all');

      if(formElemAll) {
        formElemAll.addEventListener('formdata', (e) => {       
            let data = e.formData;
            let promoCode = '';
            let dateFrom = data.get('checkin');
            let dateTo = data.get('checkout');
            let numberofguests = data.get('people');
            let numberofchildren = '';
            headerbookingurlAll = 'https://bookings.hopsoftware.com//propertygroup/Check-Inn-Hotels?promoCode=' + [promoCode] + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&numberOfAdults=' + numberofguests + '&numberOfChildren=' + numberofchildren;

        });
        formElemAll.addEventListener('submit', (e) => {
            // on form submission, prevent default
            e.preventDefault();

            // window.location.href = headerbookingurlAll;
            window.open(headerbookingurlAll, '_blank');
        });
      }
      /****** End All hotels booking widget ********/

      /* Ibadan hotel booking widget **********************/
      let headerbookingurlIbadan = 'https://bookings.hopsoftware.com/en/property/CheckInn_Ibadan?#search-tab';
      const formElemIbadan = document.querySelector('#booking-widget-ibadan');

      if(formElemIbadan) {
        formElemIbadan.addEventListener('formdata', (e) => {       
            let data = e.formData;
            let promoCode = '';
            let dateFrom = data.get('checkin');
            let dateTo = data.get('checkout');
            let numberofguests = data.get('people');
            let numberofchildren = '';
            headerbookingurlIbadan = 'https://bookings.hopsoftware.com/en/property/CheckInn_Ibadan?PropertyGroupCode=?promoCode=' + [promoCode] + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&numberOfAdults=' + numberofguests + '&numberOfChildren=' + numberofchildren;

        });
        formElemIbadan.addEventListener('submit', (e) => {
            // on form submission, prevent default
            e.preventDefault();

            // window.location.href = headerbookingurlIbadan;
            window.open(headerbookingurlIbadan, '_blank');
        });
      }
      /****** End Ibadan hotel booking widget ********/

      /* Durban hotel booking widget **********************/
      let headerbookingurlDurban = 'https://bookings.hopsoftware.com/en/property/CheckInn_Durban?#search-tab';
      const formElemDurban = document.querySelector('#booking-widget-durban');

      if(formElemDurban) {
        formElemDurban.addEventListener('formdata', (e) => {       
            let data = e.formData;
            let promoCode = '';
            let dateFrom = data.get('checkin');
            let dateTo = data.get('checkout');
            let numberofguests = data.get('people');
            let numberofchildren = '';
            headerbookingurlDurban = 'https://bookings.hopsoftware.com/en/property/CheckInn_Durban?PropertyGroupCode=?promoCode=' + [promoCode] + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&numberOfAdults=' + numberofguests + '&numberOfChildren=' + numberofchildren;

        });
        formElemDurban.addEventListener('submit', (e) => {
            // on form submission, prevent default
            e.preventDefault();

            // window.location.href = headerbookingurlDurban;
            window.open(headerbookingurlDurban, '_blank');
        });
      }
      /****** End Durban hotel booking widget ********/

      /* Signature hotel booking widget **********************/
      let headerbookingurlSignature = 'https://bookings.hopsoftware.com/en/property/CheckInn_Signature?#search-tab';
      const formElemSignature = document.querySelector('#booking-widget-signature');

      if(formElemSignature) {
      formElemSignature.addEventListener('formdata', (e) => {       
          let data = e.formData;
          let promoCode = '';
          let dateFrom = data.get('checkin');
          let dateTo = data.get('checkout');
          let numberofguests = data.get('people');
          let numberofchildren = '';
          headerbookingurlSignature = 'https://bookings.hopsoftware.com/en/property/CheckInn_Signature?PropertyGroupCode=?promoCode=' + [promoCode] + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&numberOfAdults=' + numberofguests + '&numberOfChildren=' + numberofchildren;
  
      });
      formElemSignature.addEventListener('submit', (e) => {
          // on form submission, prevent default
          e.preventDefault();
  
          // window.location.href = headerbookingurlSignature;
          window.open(headerbookingurlSignature, '_blank');
      });
      }
      /****** End Signature hotel booking widget ********/
    });
  },
};
